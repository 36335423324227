function redirectTo(url: string): Promise<undefined> {
  window.location.assign(url); // todo
  return new Promise((resolve) => setTimeout(resolve, 3000)); // window.location.assign(...) is not async so this dirty hack is used to to pause the web app init while web-home is loading
}

/**
 * Checks to see if the user got here because the SSO server redirected them here,
 * or by the user loading the login page from a bookmark or using browser history.
 *
 * It does this by adding a query param called 'login' to the url on page load (if it's not already there).
 * The server does not send this query param when it redirects here
 * but it will be here if the user loads the page from a bookmark.
 *
 * If 'login' is present then we redirect to Web Home which should kick start the auth code flow (PKCE).
 */
export async function checkIfAlreadyLoggedIn(): Promise<void> {
  const queryString = window.location.href.split('/?')[1];
  const query = new URLSearchParams(queryString);

  const redirectedByUser = query.has('login'); // The SSO server does not include this when it redirects to login. Therefore if it's here
  const invalidLoginAttempt = query.has('error');

  if (redirectedByUser && !invalidLoginAttempt) {
    return redirectTo('/web-home');
  }
  query.append('login', 'true');

  // Using replace means the user cannot press back to see previous state. Note: The hash is needed or the Vue router will add the hash after the query string
  history.replaceState(null, '', `#/?${query.toString()}`);
}
