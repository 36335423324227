import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;

const importQuasarOptions = () =>
  import(/* webpackChunkName: 'quasarOptions' */ './quasar-options');
const importI18n = () => import(/* webpackChunkName: 'i18n' */ './i18n');
const importRouter = () => import(/* webpackChunkName: 'router' */ './router');
const importApp = () => import(/* webpackChunkName: 'appComp' */ './App.vue');

import { checkIfAlreadyLoggedIn } from './logged-in-checker';

function startLoadingImportChunks() {
  importQuasarOptions();
  importRouter();
  importI18n();
  importApp();
}

async function initializeApp() {
  startLoadingImportChunks();

  await checkIfAlreadyLoggedIn();

  const [
    { QuasarOptions },
    { router },
    { initializeI18n },
    { default: App },
  ] = await Promise.all([
    importQuasarOptions(),
    importRouter(),
    importI18n(),
    importApp(),
  ]);

  createApp(App)
    .use(Quasar, QuasarOptions)
    .use(router)
    .use(initializeI18n())
    .mount('#app');
}

initializeApp();
